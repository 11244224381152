import { Box, Flex, Image } from '@vtex/store-ui'
import React from 'react'
import ShelfProducts from 'src/components/product/ProductSlider'
import ProductSummaryWhite from 'src/components/product/ProductSummaryWhite'

import styles from './styles.json'

interface Props {
  shelf: {
    image: { src: string }
    title: string
    description: string
    url: string
    searchParams: {
      collection: string
      from: number
      hideUnavailableItems: boolean
      orderBy: string
      to: number
    }
  }
}

const HydramaticShelf = ({ shelf }: Props) => {
  return (
    <Flex sx={styles.container}>
      <Box sx={styles.faixa} />

      <Flex sx={styles.box}>
        <Box sx={styles.image}>
          <Image src={shelf.image.src} alt="Batom Matte Hydramatic" />
        </Box>

        <Flex sx={styles.shelfContainer}>
          <Flex sx={styles.shelfContainer.text}>
            <h2>{shelf.title}</h2>

            <Box>
              <p>{shelf.description}</p>
              <a href={shelf.url}>Ver mais &gt;&gt;</a>
            </Box>
          </Flex>

          <ShelfProducts
            searchParams={shelf.searchParams}
            ProductSummary={ProductSummaryWhite}
            pageSizes={[1, 2]}
            showArrows
          />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default HydramaticShelf
