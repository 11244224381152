import type { FC } from 'react'
import { useEffect } from 'react'
import { useLocation } from '@reach/router'

interface Props {
  type: string
  title: string | Maybe<string> | undefined
}

const EventPageView: FC<Props> = ({ type, title }) => {
  const { href, pathname } = useLocation()

  useEffect(() => {
    window.dataLayer = window.dataLayer || []

    const { dataLayer } = window
    let data

    switch (type) {
      case 'CATEGORY': {
        data = {
          event: 'pageView',
          location: href,
          page: pathname,
          pageType: 'plp',
          referrer: document.referrer,
          title: title ?? '',
        }
        break
      }

      case 'OTHER': {
        data = {
          event: 'pageView',
          location: href,
          page: pathname,
          pageType: 'other',
          referrer: document.referrer,
          title: title ?? '',
        }
        break
      }

      case 'HOME': {
        data = {
          event: 'pageView',
          location: href,
          page: pathname,
          pageType: 'home',
          referrer: document.referrer,
          title: title ?? '',
        }
        break
      }

      case 'PRODUCT': {
        data = {
          event: 'pageView',
          location: href,
          page: pathname,
          pageType: 'pdp',
          referrer: document.referrer,
          title: title ?? '',
        }
        break
      }

      default: {
        return
      }
    }

    dataLayer.push(data)
  }, [type, href, pathname, title])

  return null
}

export default EventPageView
