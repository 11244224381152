import { useEffect } from 'react'
import type { Product } from 'src/typings'

interface Props {
  type?: string
  products?: Product[]
  list?: string
  position?: number
}

const EventImpressions = ({ type, products, list, position }: Props) => {
  useEffect(() => {
    window.dataLayer = window.dataLayer || []

    const { dataLayer } = window

    let dataImpressions: Impression = {
      event: 'impressions',
    }

    switch (type) {
      case 'CATEGORY': {
        if (!products?.[0].brand) {
          return
        }

        const variant = []

        const product = products.map((item, index) => {
          return {
            id: item?.id,
            itemId: item?.items?.[0]?.itemId,
            name: item?.productName,
            price: item?.items?.[0]?.sellers?.[0]?.commercialOffer?.price,
            category: item?.categoryTree,
            brand: item?.brand,
            list,
            position: position && position + index,
          }
        })

        variant.push(product?.id)

        dataImpressions = {
          ...dataImpressions,
          ecommerce: {
            impressions: product,
          },
        }
        break
      }

      case 'HOME': {
        const values: TValues[] = []

        products?.map((product, index) => {
          const { price } = product.items[0].sellers[0].commercialOffer
          const variantProduct = []
          const itemId = product.items.map((item) => item.itemId)

          variantProduct.push(itemId)

          return values.push({
            id: product.id,
            name: product.productName,
            price,
            brand: product.brand,
            category: product.categoryTree,
            variant: variantProduct,
            list,
            position: index,
          })
        })

        dataImpressions = {
          ...dataImpressions,
          ecommerce: {
            impressions: values,
          },
        }
        break
      }

      default: {
        return
      }
    }

    dataLayer.push(dataImpressions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

export default EventImpressions
