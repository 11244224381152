/* eslint-disable */
import React, { useEffect, useState } from 'react'
import type { FC, ReactNode } from 'react'
import { Box, Center, Shelf, ShelfContainer, Spinner } from '@vtex/store-ui'
import { gql } from '@vtex/gatsby-plugin-graphql'
import { useQuery } from '@vtex/gatsby-theme-store'
import EventImpressions from 'src/components/common/EventImpressions'
import { SuspenseViewport } from '@vtex/store-ui'
import { useColabStore } from 'src/hooks/useColabStore'
import useRepresentative from 'src/contexts/RepresentativeContext/useRepresentative'

import { BrowserShelfQuery } from './__generated__/BrowserShelfQuery.graphql'
import type {
  BrowserShelfQueryQuery,
  BrowserShelfQueryQueryVariables,
} from './__generated__/BrowserShelfQuery.graphql'
import { Product } from 'src/typings'

interface ShelfProductsProps {
  searchParams?: {
    collection: string
    from: number
    to: number
    hideUnavailableItems: boolean
    orderBy: string
  }
  pageSizes?: number[]
  products?: Product[]
  title?: string
  isStaticShelf?: boolean
  showArrows?: boolean
  ProductSummary: ReactNode
}

const ShelfProducts: FC<ShelfProductsProps> = (props) => {
  const [products, setProducts] = useState(props.products ?? undefined)
  const { isRepresentativeSelected } = useRepresentative()
  const { isUserColab } = useColabStore()
  const salesChannel = isUserColab ? '3' : isRepresentativeSelected ? '2' : '1'

  const { searchParams } = props

  const variables = {
    ...searchParams,
    salesChannel,
  }

  const { data } = useQuery<
    BrowserShelfQueryQuery,
    BrowserShelfQueryQueryVariables
  >({
    ...BrowserShelfQuery,
    variables,
  })

  useEffect(() => {
    if (!props.isStaticShelf && data !== undefined) {
      setProducts(data.vtex.products as unknown as Product[])
    }
  }, [data, data?.vtex.products, props.isStaticShelf])

  if (data === undefined && !props.isStaticShelf) {
    return (
      <Box sx={{ height: ['200px', '300px'] }}>
        <Center>
          <Spinner />
        </Center>
      </Box>
    )
  }

  return (
    <ShelfContainer>
      <Shelf
        {...props}
        products={(products as Product[]) ?? []}
        pageSizes={props.pageSizes ?? [1, 3, 4]}
      />
      <SuspenseViewport fallback={null}>
        <EventImpressions products={products} type="HOME" list={props?.title} />
      </SuspenseViewport>
    </ShelfContainer>
  )
}

export const query = gql`
  query BrowserShelfQuery(
    $from: Int!
    $to: Int!
    $collection: String!
    $orderBy: String
    $hideUnavailableItems: Boolean!
    $salesChannel: String!
  ) {
    vtex {
      products(
        from: $from
        to: $to
        collection: $collection
        orderBy: $orderBy
        hideUnavailableItems: $hideUnavailableItems
        salesChannel: $salesChannel
      ) {
        ...ProductSummary_product
      }
    }
  }
`

export default ShelfProducts
