import React from 'react'
import { Box, Flex, Image, useDevice } from '@vtex/store-ui'

import styles from './styles.json'

interface Props {
  image: {
    alt: string
    desktop: { srcSet: string }
    mobile: { srcSet: string }
  }
  text: string
}

const Representatives = ({ image, text }: Props) => {
  const device = useDevice()
  const isMobile = device === 'mobile'
  const { blocks } = JSON.parse(text)

  return (
    <Box sx={styles.container}>
      <Flex>
        <Box sx={styles.imageContainer}>
          <Image src={isMobile ? image.mobile.srcSet : image.desktop.srcSet} />
        </Box>

        <Flex sx={styles.text}>
          <h3>{blocks[0].text}</h3>
          <h4>{blocks[1].text}</h4>
          <p>{blocks[2].text}</p>
          <a href="/institucional/seja-representante">{blocks[3].text}</a>
        </Flex>
      </Flex>
    </Box>
  )
}

export default Representatives
