/** @jsx jsx */
import type { FC } from 'react'
import { jsx } from '@vtex/store-ui'

import type { ProductSummary_ProductFragment } from './__generated__/ProductSummary_product.graphql'
import ProductSummary from '../ProductSummary'

interface Props {
  product: ProductSummary_ProductFragment
  position?: number
}

const ProductSummaryWhite: FC<Props> = ({ product, position }) => {
  return <ProductSummary product={product} position={position} white />
}

export default ProductSummaryWhite
