import React from 'react'
import type { FC } from 'react'
import { Helmet } from 'react-helmet'
import type { PageProps } from 'gatsby'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import type { NatalLandingPageQueryQuery } from 'src/pages/natal-avon/__generated__/NatalLandingPageQuery.graphql'
import useRepresentative from 'src/contexts/RepresentativeContext/useRepresentative'

type Props = PageProps<NatalLandingPageQueryQuery>

const Seo: FC<Props> = (props) => {
  const siteMetadata = props.data.cmsLandingPage?.seo?.siteMetadataWithSlug

  const canonicalPathname = `https://www.avon.com.br/${siteMetadata?.slug}`
    .replace('.br//', '.br/')
    .replace('.br///', '.br/')

  const { isRepresentativeSelected } = useRepresentative()

  return (
    <>
      <Helmet>
        <link rel="canonical" href={canonicalPathname} />
      </Helmet>
      <GatsbySeo
        title={siteMetadata?.title ?? 'Mascara Legendary'}
        description={siteMetadata?.description ?? 'Mascara Legendary Avon'}
        titleTemplate={siteMetadata?.titleTemplate ?? '%s | Avon'}
        noindex={!!isRepresentativeSelected}
      />
    </>
  )
}

export default Seo
