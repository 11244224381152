import { Box, Flex } from '@vtex/store-ui'
import React from 'react'
import ShelfProducts from 'src/components/product/ProductSlider'
import ProductSummaryWhite from 'src/components/product/ProductSummaryWhite'

import styles from './styles.json'

interface Props {
  shelf:
    | {
        title: string
        description: string
        searchParams: {
          collection: string
          from: number
          hideUnavailableItems: boolean
          orderBy: string
          to: number
        }
      }
    | undefined
}

const SurpriseShelf = ({ shelf }: Props) => {
  return (
    <Flex sx={styles.container}>
      <Flex sx={styles.text}>
        <h2>{shelf?.title}</h2>
        <p>{shelf?.description}</p>
      </Flex>

      <Box sx={styles.shelf}>
        <ShelfProducts
          searchParams={shelf?.searchParams}
          ProductSummary={ProductSummaryWhite}
          pageSizes={[1, 4]}
          showArrows
        />
      </Box>
    </Flex>
  )
}

export default SurpriseShelf
