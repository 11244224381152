import React from 'react'

const Presentinho = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64.861"
      height="66.616"
      viewBox="0 0 64.861 66.616"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Retângulo_4392"
            data-name="Retângulo 4392"
            width="64.861"
            height="66.616"
            fill="none"
          />
        </clipPath>
      </defs>
      <g id="presntinho" transform="translate(-4.768 -4.69)">
        <g
          id="Grupo_9910"
          data-name="Grupo 9910"
          transform="translate(4.768 4.69)"
        >
          <g
            id="Grupo_9909"
            data-name="Grupo 9909"
            transform="translate(0 0)"
            clipPath="url(#clip-path)"
          >
            <path
              id="Caminho_7173"
              data-name="Caminho 7173"
              d="M2.648,33.465l.266.131v6.146a1.517,1.517,0,0,0,.041.348l0,21.677s-.008,4.513,3.877,4.764S58.625,66.4,58.625,66.4a6.728,6.728,0,0,0,3.324-2.823l0-29.987.267-.131a4.676,4.676,0,0,0,2.641-4.206V20.35A7.526,7.526,0,0,0,57.27,13.1H52.2l.417-.717A8.382,8.382,0,0,0,51.393,2.366a8.358,8.358,0,0,0-11.653,0A58.527,58.527,0,0,0,32.827,11l-.4.594-.4-.594a58.65,58.65,0,0,0-6.912-8.631,8.362,8.362,0,0,0-11.655,0,8.385,8.385,0,0,0-1.219,10.019l.417.717H7.589A7.525,7.525,0,0,0,0,20.35v8.907a4.663,4.663,0,0,0,2.648,4.208m18.311,29.5L12.309,63c-1.742,0-2.666-.871-3.831-1.97-.674-.635-.868-2.91-.743-4.438V35.727H20.959ZM57.13,58.728a4.474,4.474,0,0,1-4.574,4.234H43.9l0-27.2H57.13Zm.139-40.806c2.479,0,2.769.135,2.769,2.428v7.1a1.69,1.69,0,0,1-1.687,1.688H43.9l0-11.218ZM35.55,12.359A55.652,55.652,0,0,1,41.873,4.5a5.426,5.426,0,0,1,7.388,0,5.289,5.289,0,0,1,1.342,5,5.383,5.383,0,0,1-.349.981,5.075,5.075,0,0,1-.991,1.408,6.868,6.868,0,0,1-1.455,1.157L35.048,13.1Zm1.726,5.563,0,45.041H27.585V17.922ZM15.6,4.5a5.42,5.42,0,0,1,7.388,0,55.655,55.655,0,0,1,6.324,7.862l.5.743H17.162l-.108-.059A6.713,6.713,0,0,1,15.6,11.886a5.294,5.294,0,0,1,0-7.388M4.82,20.35c0-2.294.29-2.428,2.769-2.428H20.959V29.14H6.508A1.691,1.691,0,0,1,4.82,27.452Z"
              transform="translate(0 0)"
              fill="#e01b3c"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Presentinho
