import type { FC } from 'react'
import React from 'react'
import type { PageProps } from 'gatsby'
import CommonQuestions from 'src/components/common/CommonQuestions'
import type { NatalLandingPageQueryQuery } from 'src/pages/natal-avon/__generated__/NatalLandingPageQuery.graphql'
import SurpriseShelf from 'src/components/natal-avon/SurpriseShelf'
import HydramaticShelf from 'src/components/natal-avon/HydramaticShelf'
import Representatives from 'src/components/natal-avon/Representatives'

type Props = PageProps<NatalLandingPageQueryQuery>

const BelowTheFold: FC<Props> = ({ data: { cmsLandingPage } }) => {
  return (
    <>
      <SurpriseShelf shelf={cmsLandingPage?.sections[11].data} />
      <HydramaticShelf shelf={cmsLandingPage?.sections[12].data} />
      <Representatives
        image={cmsLandingPage?.sections[13].data}
        text={cmsLandingPage?.sections[14].data.content}
      />
      <CommonQuestions theme="dark" />
    </>
  )
}

export default BelowTheFold
