import React, { useState } from 'react'
import {
  Box,
  Flex,
  Image,
  SliderArrowLeft,
  SliderArrowRight,
  useDevice,
  useUISlider,
} from '@vtex/store-ui'
import ProductSummaryWhite from 'src/components/product/ProductSummaryWhite'
import { ArrowDownIcon } from 'src/components/icons'
import Presentinho from 'src/components/icons/Presentinho'
import type { ProductSummary_ProductFragment } from 'src/components/product/ProductSummary/__generated__/ProductSummary_product.graphql'
import ShelfProducts from 'src/components/product/ProductSlider'
import type { ProductPageType } from 'src/views/product/components/typings/product'

import styles from './styles.json'

interface Props {
  text: string
  categoriesShelf:
    | Array<{
        data: {
          image: { src: string }
          title: string
          searchParams: {
            collection: string
            from: number
            hideUnavailableItems: boolean
            orderBy: string
            to: number
          }
        }
      }>
    | undefined

  products: ProductSummary_ProductFragment[]
}

const gradients = [
  'transparent linear-gradient(90deg, #EAC9FF 0%, #EF77BA 100%) 0% 0% no-repeat padding-box',
  'transparent linear-gradient(90deg, #6606A2 0%, #D3A0FF 100%) 0% 0% no-repeat padding-box',
  'transparent linear-gradient(90deg, #002A16 0%, #79FFC1 100%) 0% 0% no-repeat padding-box',
  'transparent linear-gradient(90deg, #FFC9CE 0%, #F72B2B 100%) 0% 0% no-repeat padding-box',
  'transparent linear-gradient(90deg, #C9CEFF 0%, #7787EF 100%) 0% 0% no-repeat padding-box',
  'transparent linear-gradient(90deg, #8DDBE1 0%, #0098A2 100%) 0% 0% no-repeat padding-box',
  'transparent linear-gradient(90deg, #F8E6B5 0%, #F1BD43 100%) 0% 0% no-repeat padding-box',
  'transparent linear-gradient(90deg, #FFC9C9 0%, #EA2828 100%) 0% 0% no-repeat padding-box',
]

const CategoriesShelf = ({ text, categoriesShelf, products }: Props) => {
  const [categoryActive, setCategoryActive] = useState('Kits e Presentes')

  const device = useDevice()
  const isMobile = device === 'mobile'

  const { blocks } = JSON.parse(text)

  const selectedShelf = categoriesShelf?.filter(
    ({ data }) => data.title === categoryActive
  )

  const { items, setPreviousPage, setNextPage, dragHandlers } = useUISlider({
    allItems: categoriesShelf ?? [],
    pageSize: 6,
  })

  return (
    <Flex sx={styles.container}>
      <Flex sx={styles.topBox}>
        <h1>
          PARA TODO MUNDO QUE TEM UM LUGAR ESPECIAL NO SEU CORAÇÃO:
          #VemDePresenteAVON
        </h1>
      </Flex>

      <Flex sx={styles.box}>
        <Flex>
          <p>Compre seus presentes por categorias:</p>

          <Flex sx={styles.carouselContainer} {...dragHandlers}>
            <SliderArrowLeft
              sx={styles.arrow}
              aria-label="Previous Carousel Image"
              onClick={() => setPreviousPage()}
            />

            <Box sx={styles.categories}>
              {!isMobile
                ? items?.map(({ data: category }, index) => (
                    <Flex
                      key={category.title}
                      sx={{
                        p: {
                          fontWeight:
                            categoryActive === category.title ? [700] : [300],
                        },
                      }}
                      onClick={() => setCategoryActive(category.title)}
                    >
                      {categoryActive === category.title && <Presentinho />}
                      <Box
                        sx={{
                          ...styles.imageContainer,
                          border: [
                            categoryActive === category.title
                              ? '4px solid #FF4060'
                              : 'none',
                          ],
                          background: [gradients[index]],

                          img: {
                            scale: [
                              categoryActive === category.title
                                ? '1.05'
                                : '0.85',
                            ],
                          },
                        }}
                      >
                        <Image src={category.image.src} />
                      </Box>
                      <p>{category.title}</p>
                      {categoryActive === category.title && <ArrowDownIcon />}
                    </Flex>
                  ))
                : categoriesShelf?.map(({ data: category }, index) => (
                    <Flex
                      key={category.title}
                      sx={{
                        p: {
                          fontWeight:
                            categoryActive === category.title ? [700] : [300],
                        },
                      }}
                      onClick={() => setCategoryActive(category.title)}
                    >
                      {categoryActive === category.title && <Presentinho />}
                      <Box
                        sx={{
                          ...styles.imageContainer,
                          border: [
                            categoryActive === category.title
                              ? '3px solid #FF4060'
                              : 'none',
                          ],
                          background: [gradients[index]],

                          img: {
                            scale: [
                              categoryActive === category.title
                                ? '1.05'
                                : '0.85',
                            ],
                          },
                        }}
                      >
                        <Image src={category.image.src} />
                      </Box>
                      <p>{category.title}</p>
                      {categoryActive === category.title && <ArrowDownIcon />}
                    </Flex>
                  ))}
            </Box>

            <SliderArrowRight
              sx={styles.arrow}
              aria-label="Next Carousel Image"
              onClick={() => setNextPage()}
            />
          </Flex>

          <Flex sx={styles.text}>
            <h2>{blocks[0].text}</h2>
            <p>{blocks[1].text}</p>
          </Flex>

          <Box sx={styles.shelf}>
            <ShelfProducts
              products={products as ProductPageType[]}
              searchParams={selectedShelf?.[0].data.searchParams}
              ProductSummary={ProductSummaryWhite}
              pageSizes={[1, 4]}
              showArrows
            />
          </Box>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default CategoriesShelf
