import { Box } from '@vtex/store-ui'
import { graphql } from 'gatsby'
import type { FC } from 'react'
import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from 'src/components/Layout'
import { View } from 'src/components/ui/View'
import AboveTheFold from 'src/views/natal-avon/AboveTheFold'
import BelowTheFold from 'src/views/natal-avon/BelowTheFold'
import Seo from 'src/views/natal-avon/Seo'

const ViewComponents = {
  seo: Seo,
  above: AboveTheFold,
  below: {
    component: BelowTheFold,
  },
} as const

const Page: FC = (props) => (
  <>
    <Helmet>
      <link rel="canonical" href="https://www.avon.com.br/natal-avon" />
      <meta
        name="google-site-verification"
        content="S-rBD1N1hlPGZvyggH0yXKNVKIbsDStZpnvob1pBGdE"
      />
    </Helmet>
    <Layout>
      <Box
        sx={{
          background:
            'transparent radial-gradient(closest-side at 50% 50%, #D66686 0%, #9E1C2E 52%, #800F2C 100%) 0% 0% no-repeat padding-box',
          color: '#FFF',
          fontFamily: 'Zona Pro, sans-serif',
        }}
      >
        <View {...ViewComponents} data={props} />
      </Box>
    </Layout>
  </>
)

export const query = graphql`
  query NatalLandingPageQuery(
    $categoriesShelfCollection: String!
    $categoriesShelfFrom: Int!
    $categoriesShelfHideUnavailableItems: Boolean!
    $categoriesShelfOrderBy: String
    $categoriesShelfTo: Int!
    $countdownShelfCollection: String!
    $countdownShelfFrom: Int!
    $countdownShelfHideUnavailableItems: Boolean!
    $countdownShelfOrderBy: String
    $countdownShelfTo: Int!
    $LovUCollection: String!
    $LovUFrom: Int!
    $LovUHideUnavailableItems: Boolean!
    $LovUOrderBy: String
    $LovUTo: Int!
  ) {
    cmsLandingPage(name: { eq: "Natal" }) {
      sections {
        data
        name
      }
      seo {
        siteMetadataWithSlug {
          title
          description
          titleTemplate
          slug
        }
      }
    }
    vtex {
      categoriesProducts: products(
        collection: $categoriesShelfCollection
        from: $categoriesShelfFrom
        to: $categoriesShelfTo
        orderBy: $categoriesShelfOrderBy
        hideUnavailableItems: $categoriesShelfHideUnavailableItems
      ) {
        ...ProductSummary_product
      }

      countdownProducts: products(
        collection: $countdownShelfCollection
        from: $countdownShelfFrom
        to: $countdownShelfTo
        orderBy: $countdownShelfOrderBy
        hideUnavailableItems: $countdownShelfHideUnavailableItems
      ) {
        ...ProductSummary_product
      }

      LovUProducts: products(
        collection: $LovUCollection
        from: $LovUFrom
        to: $LovUTo
        orderBy: $LovUOrderBy
        hideUnavailableItems: $LovUHideUnavailableItems
      ) {
        ...ProductSummary_product
      }
    }
  }
`

export default Page
