/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { Center, Spinner, SuspenseViewport } from '@vtex/store-ui'
import type { PropsWithChildren, CSSProperties } from 'react'

interface Props<D> {
  data: D
  seo: React.FunctionComponent<any>
  above: React.FunctionComponent<any>
  below?: {
    component: React.FunctionComponent<any>
    preloader?: () => Promise<unknown>
  }
}

export const View = <D,>(props: PropsWithChildren<Props<D>>) => (
  <>
    <props.seo {...props.data} />
    <props.above {...props.data} />
    {props.below?.preloader && (
      <SuspenseViewport
        preloader={props.below.preloader}
        fallback={
          <div
            style={
              {
                contentVisibility: 'auto',
                containIntrinsicSize: '500px',
              } as unknown as CSSProperties
            }
          >
            <Center height="500px">
              <Spinner />
            </Center>
          </div>
        }
      >
        <props.below.component {...props.data} />
      </SuspenseViewport>
    )}
    {props.below && !props.below.preloader && (
      <props.below.component {...props.data} />
    )}
  </>
)
