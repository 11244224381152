import React from 'react'
import type { FC } from 'react'
import type { PageProps } from 'gatsby'
import type { NatalLandingPageQueryQuery } from 'src/pages/natal-avon/__generated__/NatalLandingPageQuery.graphql'
import EventPageView from 'src/components/common/EventPageView'
import CategoriesShelf from 'src/components/natal-avon/CategoriesShelf'
import LovU from 'src/components/natal-avon/LovU'
import CountdownShelf from 'src/components/natal-avon/CountdownShelf'
import type { ProductSummary_ProductFragment } from 'src/components/product/ProductSummary/__generated__/ProductSummary_product.graphql'

type Props = PageProps<NatalLandingPageQueryQuery>

const AboveTheFold: FC<Props> = ({ data: { cmsLandingPage, vtex } }) => {
  const categoriesShelf = cmsLandingPage?.sections.slice(0, 7)

  return (
    <>
      <EventPageView
        type="OTHER"
        title={cmsLandingPage.seo.siteMetadataWithSlug.title}
      />
      <CategoriesShelf
        text={cmsLandingPage?.sections[7].data.content}
        categoriesShelf={categoriesShelf}
        products={vtex.categoriesProducts as ProductSummary_ProductFragment[]}
      />
      <CountdownShelf
        products={vtex.countdownProducts as ProductSummary_ProductFragment[]}
        shelf={cmsLandingPage?.sections[8].data}
      />
      <LovU
        products={vtex.LovUProducts as ProductSummary_ProductFragment[]}
        image={cmsLandingPage?.sections[9].data}
        shelf={cmsLandingPage?.sections[10].data}
      />
    </>
  )
}

export default AboveTheFold
