import React from 'react'
import { Box, Flex, Image } from '@vtex/store-ui'
import ProductSummaryWhite from 'src/components/product/ProductSummaryWhite'
import ShelfProducts from 'src/components/product/ProductSlider'
import type { ProductSummary_ProductFragment } from 'src/components/product/SingleProductSummary/__generated__/ProductSummary_product.graphql'
import type { ProductPageType } from 'src/views/product/components/typings/product'

import styles from './styles.json'

interface Props {
  image: {
    alt: string
    desktop: {
      srcSet: string
    }
    mobile: {
      srcSet: string
    }
  }
  shelf: {
    description: string
    searchParams: {
      hideUnavailableItems: true
      orderBy: string
      from: number
      to: number
      collection: string
    }
    title: string
    url: string
  }
  products: ProductSummary_ProductFragment[]
}

const LovU = ({ image, shelf, products }: Props) => {
  return (
    <Flex sx={styles.container}>
      <Box sx={styles.imageContainer}>
        <Image alt="Caixa de presente Lov U" src={image.desktop.srcSet} />
        <p>{image.alt}</p>
      </Box>

      <Flex sx={styles.shelfContainer}>
        <Flex sx={styles.shelfContainer.text}>
          <h2>
            LOV U TOUCH
            <br /> O TOQUE DO AMOR
          </h2>

          <Box>
            <p>{shelf.description}</p>
            <a href={shelf.url}>Ver mais &gt;&gt;</a>
          </Box>
        </Flex>

        <Box sx={{ width: ['97vw', 'auto'] }}>
          <ShelfProducts
            ProductSummary={ProductSummaryWhite}
            products={products as ProductPageType[]}
            pageSizes={[1, 2]}
            isStaticShelf
            showArrows
          />
        </Box>
      </Flex>
    </Flex>
  )
}

export default LovU
