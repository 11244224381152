import React, { useState } from 'react'
import { Box, Flex, Image, Text } from '@vtex/store-ui'
import ProductSummaryWhite from 'src/components/product/ProductSummaryWhite'
import type { ProductSummary_ProductFragment } from 'src/components/product/ProductSummary/__generated__/ProductSummary_product.graphql'
import ShelfProducts from 'src/components/product/ProductSlider'
import type { ProductPageType } from 'src/views/product/components/typings/product'

import styles from './styles.json'

interface Props {
  products: ProductSummary_ProductFragment[]
  shelf:
    | {
        title: string
        description: string
        searchParams: {
          collection: string
          from: number
          hideUnavailableItems: boolean
          orderBy: string
          to: number
        }
      }
    | undefined
}

const CountdownShelf = ({ shelf, products }: Props) => {
  const [remainingTime, setRemainingTime] = useState({
    hours: 23,
    minutes: 59,
    seconds: 59,
  })

  setInterval(() => {
    const date = new Date()

    setRemainingTime({
      hours: 23 - date.getHours(),
      minutes: 59 - date.getMinutes(),
      seconds: 59 - date.getSeconds(),
    })
  }, 1000)

  const formattedHours =
    remainingTime.hours < 10
      ? remainingTime.hours.toString().padStart(2, '0')
      : remainingTime.hours

  const formattedMinutes =
    remainingTime.minutes < 10
      ? remainingTime.minutes.toString().padStart(2, '0')
      : remainingTime.minutes

  const formattedSeconds =
    remainingTime.seconds < 10
      ? remainingTime.seconds.toString().padStart(2, '0')
      : remainingTime.seconds

  return (
    <Box sx={styles.container}>
      <Flex sx={styles.box}>
        <Flex sx={styles.text}>
          <Box sx={styles.text.imageBox}>
            <Image
              src="/arquivos/natal-avon-countdown-image.png"
              alt="Caixas de presente"
            />
          </Box>

          <Flex sx={styles.countdownContainer}>
            <h3>TERMINA EM:</h3>

            <Flex sx={styles.countdownContainer.countdown}>
              <Box>
                <strong>{formattedHours}</strong> <p>horas</p>
              </Box>
              <span>:</span>
              <Box>
                <strong>{formattedMinutes}</strong> <p>minutos</p>
              </Box>
              <span>:</span>
              <Box>
                <strong>{formattedSeconds}</strong> <p>segundos</p>
              </Box>
            </Flex>
          </Flex>

          <Text as="p">{shelf?.description}</Text>
        </Flex>

        <Box sx={styles.shelf}>
          <ShelfProducts
            ProductSummary={ProductSummaryWhite}
            products={products as ProductPageType[]}
            pageSizes={[1, 4]}
            isStaticShelf
            showArrows
          />
        </Box>
      </Flex>
    </Box>
  )
}

export default CountdownShelf
